import React, { useState, useRef, useEffect } from 'react';
import Spline from '@splinetool/react-spline';
import { motion, AnimatePresence } from 'framer-motion';
import 'bootstrap/dist/css/bootstrap.min.css';
import './App.css';
import axios from 'axios';
import Cookies from 'js-cookie';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

import { useLanguage } from './context/LanguageContext';
import translations from './translations';


import btStory from "./assets/images/bt_story.png";
import btCha from "./assets/images/bt_cha.png";
import btPro from "./assets/images/bt_pro.png";
import btUp from "./assets/images/bt_update.png";
import btCon from "./assets/images/bt_contract.png";
import btGallery from "./assets/images/bt_gall.png";
import bt_playS_p from "./assets/images/btPlaySound_play.png";
import bt_playS_s from "./assets/images/btPlaySound_stop.png";
import logoBrand from "./assets/images/TitleTexts.png";
import bomForm from "./assets/images/bottomForm.png";
import instagramIcon from './assets/images/ig.png';
import youtubeIcon from './assets/images/yt.png';
import vdoDevar from './assets/vdo/dewigiv11.mp4';
import backgroundMusic from './assets/sounds/musicBGDeVa.mp3';
import updateImage from './assets/images/updatev11.png';

import logowigi from "./assets/images/logoWigiweb.png";

import icon_gallery from './assets/images/icon-gallery.png';
import icon_like from './assets/images/icon-like.png';
import icon_liked from './assets/images/icon-liked.png';


import gall_1 from "./assets/images/gallery/gall_1.png";
import gall_2 from "./assets/images/gallery/gall_2.png";
import gall_3 from "./assets/images/gallery/gall_3.png";
import gall_4 from "./assets/images/gallery/gall_4.png";
import gall_5 from "./assets/images/gallery/gall_5.png";
import gall_6 from "./assets/images/gallery/gall_6.png";
import gall_7 from "./assets/images/gallery/g_d_1.png";
import gall_8 from "./assets/images/gallery/g_d_2.png";
import gall_9 from "./assets/images/gallery/g_d_3.png";
import gall_10 from "./assets/images/gallery/g_d_4.png";
import gall_11 from "./assets/images/gallery/gall_7.png";
import gall_12 from "./assets/images/gallery/gall_8.png";
import gall_13 from "./assets/images/gallery/gall_9.png";

import gall_14 from "./assets/images/gallery/wigi_d_pho_v00001.png";
import gall_15 from "./assets/images/gallery/wigi_d_pho_v00002.png";
import gall_16 from "./assets/images/gallery/wigi_d_pho_v00003.png";
import gall_17 from "./assets/images/gallery/wigi_d_pho_v00004.png";
import gall_18 from "./assets/images/gallery/wigi_d_pho_v00005.png";
import gall_19 from "./assets/images/gallery/wigi_d_pho_v00006.png";
import gall_20 from "./assets/images/gallery/wigi_d_pho_v00007.png";
import gall_21 from "./assets/images/gallery/wigi_d_pho_v00008.png";
import gall_22 from "./assets/images/gallery/wigi_d_pho_v00009.png";
import gall_23 from "./assets/images/gallery/wigi_d_pho_v00010.png";
import gall_24 from "./assets/images/gallery/wigi_d_pho_v00011.png";
import gall_25 from "./assets/images/gallery/wigi_d_pho_v00012.png";
import gall_26 from "./assets/images/gallery/wigi_d_pho_v00013.png";
import gall_27 from "./assets/images/gallery/wigi_d_pho_v00014.png";
import gall_28 from "./assets/images/gallery/wigi_d_pho_v00015.png";
import gall_29 from "./assets/images/gallery/wigi_d_pho_v00016.png";
import gall_30 from "./assets/images/gallery/wigi_d_pho_v0001111.png";
import gall_31 from "./assets/images/gallery/wigi_d_pho_v0001112.png";


const gallerySets = [
  {
    name: "Image 1",
    images: gall_1
  },
  {
    name: "Image 2",
    images: gall_2
  },
  {
    name: "Image 3",
    images: gall_3
  },
  {
    name: "Image 4",
    images: gall_4
  },
  {
    name: "Image 5",
    images: gall_5
  },
  {
    name: "Image 6",
    images: gall_6
  },
  {
    name: "Image 7",
    images: gall_7
  },
  {
    name: "Image 8",
    images: gall_8
  },
  {
    name: "Image 9",
    images: gall_9
  },
  {
    name: "Image 10",
    images: gall_10
  },
  {
    name: "Image 11",
    images: gall_11
  },
  {
    name: "Image 12",
    images: gall_12
  }
  ,
  {
    name: "Image 13",
    images: gall_13
  },

  {
    name: "Image 14",
    images: gall_14
  },
  {
    name: "Image 15",
    images: gall_15
  },
  {
    name: "Image 16",
    images: gall_16
  },
  {
    name: "Image 17",
    images: gall_17
  },
  {
    name: "Image 18",
    images: gall_18
  },
  {
    name: "Image 19",
    images: gall_19
  }
  ,
  {
    name: "Image 20",
    images: gall_20
  }
  ,
  {
    name: "Image 21",
    images: gall_21
  }
  ,
  {
    name: "Image 22",
    images: gall_22
  }
  ,
  {
    name: "Image 23",
    images: gall_23
  }
  ,
  {
    name: "Image 24",
    images: gall_24
  }
  ,
  {
    name: "Image 25",
    images: gall_25
  }
  ,
  {
    name: "Image 26",
    images: gall_26
  }
  ,
  {
    name: "Image 27",
    images: gall_27
  }
  ,
  {
    name: "Image 28",
    images: gall_28
  }
  ,
  {
    name: "Image 29",
    images: gall_29
  }
  ,
  {
    name: "Image 30",
    images: gall_30
  }
  ,
  {
    name: "Image 31",
    images: gall_31
  }
  // Add more images as needed
];

function App() {
  const { language, toggleLanguage } = useLanguage();
  const t = translations[language];
  const isEnglish = language === 'en';


  const storySets = [
    {
      name: t.story_title,
      images: [t.story_1, t.story_2]
    }
  ];
  
  const CharactersSets = [
    {
      name: t.characters_deva,
      images: [t.chaDeva_1, t.chaDeva_2]
    },
    {
      name: t.characters_tud,
      images: [t.chaTudTeva_1, t.chaTudTeva_2]
    },
    {
      name: t.characters_pang,
      images: [t.chaPang_1, t.chaPang_2]
    },
    {
      name: t.characters_mons,
      images: [t.chaMons_1, t.chaMons_2]
    },
    {
      name: t.characters_boss,
      images: [t.chaBoss_1, t.chaBoss_2]
    }
  ];
  
  const [isPlaying, setIsPlaying] = useState(false);
  const [showUpdate, setShowUpdate] = useState(false);
  const [showContactForm, setShowContactForm] = useState(false);
  const [showStory, setShowStory] = useState(false);
  const [currentStory, setCurrentStory] = useState(storySets[0]);
  const [showCharacters, setShowCharacters] = useState(false);
  const [currentCha, setCurrentCha] = useState(CharactersSets[0]);
  const [showGallery, setShowGallery] = useState(false);
  const [selectedId, setSelectedId] = useState<string | null>(null);
  const [selectedImage, setSelectedImage] = useState<string | null>(null);
  const [isLoading, setIsLoading] = useState(true);
  const [menuOpen, setMenuOpen] = useState(false);
  const [showPrivacy, setShowPrivacy] = useState(false);

  const [senderName, setSenderName] = useState('');
  const [message, setMessage] = useState('');

  const [isSubmitting, setIsSubmitting] = useState(false);
  const [submissionStatus, setSubmissionStatus] = useState<string | null>(null);
  const [isWaiting, setIsWaiting] = useState(false);

  const [cookieAccepted, setCookieAccepted] = useState(false);
  const [cookieRejected, setCookieRejected] = useState(false);

  const [isDevButtonDisabled, setIsDevButtonDisabled] = useState(false);


  
  
  const audioRef = useRef<HTMLAudioElement>(null);

  useEffect(() => {
    const lastSubmittedTime = Cookies.get('lastSubmittedTime');
    if (lastSubmittedTime && Date.now() - parseInt(lastSubmittedTime) < 3600000) {
      setIsWaiting(true);
    }

    const cookieConsent = Cookies.get('cookieConsent');
    if (cookieConsent === 'true') {
      setCookieAccepted(true);
    } else if (cookieConsent === 'false') {
      setCookieRejected(true);
    }

    const lastDevButtonClickedTime = Cookies.get('lastDevButtonClickedTime');
    if (lastDevButtonClickedTime) {
      const timeElapsed = Date.now() - parseInt(lastDevButtonClickedTime, 10);
      if (timeElapsed < 60000) { // 1 นาที = 60000 มิลลิวินาที
        setIsDevButtonDisabled(true);
        setTimeout(() => {
          setIsDevButtonDisabled(false);
        }, 60000 - timeElapsed);
      }
    }

    const path = window.location.pathname;

    if (path === '/story') {
      setShowStory(true);
    } else if (path === '/characters') {
      setShowCharacters(true);
    } else if (path === '/contact') {
      setShowContactForm(true);
    } else if (path === '/gallery') {
      setShowGallery(true);
    } else if (path === '/privacy') {
      setShowPrivacy(true);
    } else if (path === '/update') {
      setShowPrivacy(true);
    }

    const handlePopState = () => {
      if (window.history.state && window.history.state.customBack) {
        console.log("back");
        window.location.reload();
      } else {
        setShowContactForm(false);
        setShowStory(false);
        setShowGallery(false);
        setShowCharacters(false);
        setShowUpdate(false);
        setShowPrivacy(false);
      }
    };

    window.addEventListener('popstate', handlePopState);

  
    const timer = setTimeout(() => {
      setIsLoading(false);
    }, 2000);


    return () => {
      window.removeEventListener('popstate', handlePopState);
      clearTimeout(timer);
    };
  }, []);

  const toggleMusic = () => {
    if (audioRef.current) {
      const { current } = audioRef;
      const playPromise = isPlaying ? current.pause() : current.play();

      if (playPromise !== undefined) {
        playPromise.then(() => {
          setIsPlaying(!isPlaying);
        }).catch(error => {
          console.error('Failed to play or pause:', error);
        });
      } else {
        setIsPlaying(!isPlaying);
      }
    }
  };

  const handleAcceptCookies = () => {
    Cookies.set('cookieConsent', 'true', { expires: 365 });
    setCookieAccepted(true);
    setCookieRejected(false);
  };
  
  const handleRejectCookies = () => {
    Cookies.set('cookieConsent', 'false', { expires: 365 });
    setCookieAccepted(false);
    setCookieRejected(true);
  };

  const handleSubmit = async (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault();
  
    if (!cookieAccepted && !cookieRejected) {
      toast.warn(t.alert_1);
      return;
    }
  
    const lastSubmittedTime = Cookies.get('lastSubmittedTime');
    if (lastSubmittedTime && Date.now() - parseInt(lastSubmittedTime, 10) < 3600000) {
      toast.warn(t.alert_2);
      return;
    }
  
    try {
      setIsLoading(true);
      const response = await axios.post('https://wigiway-api.vercel.app/api/inboxMessage', {
        name: senderName,
        message: message,
      });
      console.log('Message sent:', response.data);
      setSenderName('');
      setMessage('');
      Cookies.set('lastSubmittedTime', Date.now().toString(), { expires: 1 });
      toast.success(t.alert_3);
    } catch (error) {
      console.error('Error sending message:', error);
      toast.error(t.alert_4);
    } finally {
      setIsLoading(false);
    }
  };
  
  const handleDevButtonClick = async () => {
    setIsDevButtonDisabled(true);
    Cookies.set('lastDevButtonClickedTime', Date.now().toString(), { expires: 1 / 24 / 60 }); // ตั้งเวลา 1 นาที
  
    try {
      await axios.post('https://wigiway-api.vercel.app/api/fanclubSupportLike', {supportId:"from support", score: 1 });
      console.log('Score sent successfully');
    } catch (error) {
      console.error('Error sending score:', error);
    }
  
    setTimeout(() => {
      setIsDevButtonDisabled(false);
    }, 60000); // 1 นาที
  };
  
  
  

  const toggleUpdate = () => {
    setShowUpdate(!showUpdate);
    window.history.pushState({ customBack: true }, '', '/update');
  };

  const toggleContactForm = () => {
    setShowContactForm(!showContactForm)
    window.history.pushState({ customBack: true }, '', '/contact');
  };
  const toggleStory = () => {
    setShowStory(!showStory)
    window.history.pushState({ customBack: true }, '', '/story');
  };
  const toggleCharacters = () => {
    setShowCharacters(!showCharacters)
    window.history.pushState({ customBack: true }, '', '/characters');
  };
  const toggleGallery = () => {
    setShowGallery(!showGallery)
    window.history.pushState({ customBack: true }, '', '/gallery');
  };
  const toggleMenu = () => {
    setMenuOpen(!menuOpen)
  };
  const togglePrivacy = () => {
    window.history.pushState({ customBack: true }, '', '/privacy');
    setShowPrivacy(!showPrivacy)
  };

  const close = () => {
    setShowUpdate(false);
    setShowStory(false);
    setShowGallery(false);
    setShowContactForm(false);
    setShowPrivacy(false);
    setShowCharacters(false);
    window.history.pushState(null, '', '/');
  }

  const handleImageClick = (id: string, image: string) => {
    setSelectedId(id);
    setSelectedImage(image);
  };

  const handleCloseImage = () => {
    setSelectedId(null);
    setSelectedImage(null);
  };


  const spring = {
    type: "spring",
    stiffness: 700,
    damping: 30
  };

  return (
    <div className="hero-image">
      <AnimatePresence>
        {isLoading && (
          <motion.div
            initial={{ opacity: 1 }}
            animate={{ opacity: 1 }}
            exit={{ opacity: 0 }}
            transition={{ duration: 1.5 }}
            className="loading-panel"
          >
            <Spline scene="https://prod.spline.design/6NPxQqKm7jKU2bPx/scene.splinecode" />
          </motion.div>
        )}
      </AnimatePresence>

      <video autoPlay loop muted playsInline className="hero-video">
        <source src={vdoDevar} type="video/mp4" />
        Your browser does not support the video tag.
      </video>



      <div className="black-bar"></div>

      {showStory && (
        <motion.div
          initial={{ opacity: 0 }}
          animate={{ opacity: 1 }}
          className="story-panel"
        >
          <button className="close-button" onClick={close}>CLOSE</button>
          <div className="story-content">
            <motion.h1
              initial={{ scale: 0 }}
              animate={{ scale: 1 }}
              transition={{ delay: 0.5, duration: 0.1 }}
            >
              <h4>{currentStory.name}</h4>
            </motion.h1>
            <motion.div
              initial={{ opacity: 0 }}
              animate={{ opacity: 1 }}
              transition={{ delay: 0.8, duration: 0.5 }}
              className="image-container"
            >
              <img src={currentStory.images[0]} alt="Storyboard Image 1" className="story-image" />
              <img src={currentStory.images[1]} alt="Storyboard Image 2" className="story-image" />
            </motion.div>
          </div>
        </motion.div>
      )}

      {showCharacters && (
        <motion.div
          initial={{ opacity: 0 }}
          animate={{ opacity: 1 }}
          exit={{ opacity: 0 }}
          transition={{ duration: 0.5 }}
          className="characters-panel"
        >
          <button className="close-button" onClick={close}>CLOSE</button>
          <div className="characters-content">
            <h4>{currentCha.name}</h4>
            <motion.div
              initial={{ opacity: 0 }}
              animate={{ opacity: 1 }}
              transition={{ delay: 0.8, duration: 0.5 }}
              className="image-container"
            >
              <img src={currentCha.images[0]} alt="Storyboard Image 1" className="characters-image" />
              <img src={currentCha.images[1]} alt="Storyboard Image 2" className="characters-image" />
            </motion.div>
            <motion.div
              initial={{ scale: 0 }}
              animate={{ scale: 1 }}
              transition={{ delay: 0.7, duration: 0.1 }}
              className="characters-buttons"
            >
              {CharactersSets.map(set => (
                <button key={set.name} onClick={() => setCurrentCha(set)}>
                  {set.name}
                </button>
              ))}
            </motion.div>
          </div>
        </motion.div>
      )}

      {showGallery && (
        <motion.div
          initial={{ opacity: 0 }}
          animate={{ opacity: 1 }}
          exit={{ opacity: 0 }}
          transition={{ duration: 0.5 }}
          className="gallery-panel"
        >
          <button className="close-button" onClick={close}>CLOSE</button>
          
          <div className="gallery-content">
          <div className='gallery-topInfo'>
            <img className='iconGallery' src={icon_gallery} alt="IconGallery"/>
            <hr/>
            <h6>Gallery</h6>
          </div>

            <div className="gallery-grid">
              {gallerySets.map((item, index) => (
                <motion.div
                  key={index}
                  layoutId={item.name}
                  onClick={() => handleImageClick(item.name, item.images)}
                  className="gallery-item"
                >
                  <img src={item.images} alt={item.name} />
                 
                </motion.div>
              ))}
            </div>
          </div>
        </motion.div>
      )}

      <AnimatePresence>
        {selectedId && (
          <motion.div
            layoutId={selectedId}
            initial={{ opacity: 0 }}
            animate={{ opacity: 1 }}
            exit={{ opacity: 0 }}
            transition={{ duration: 0.5 }}
            className="selected-image"
            onClick={handleCloseImage}
          >
            {selectedImage && <img src={selectedImage} alt={selectedId} />}
            <button className="close-button" onClick={handleCloseImage}>CLOSE</button>
          </motion.div>
        )}
      </AnimatePresence>

      {showUpdate && (
        <motion.div 
          initial={{ x: '100vw' }}
          animate={{ x: 0 }}
          transition={{ type: 'spring', stiffness: 80 }}
          className={`update-panel ${showUpdate ? 'active' : ''}`}
        >
          <button className="close-button" onClick={close}>CLOSE</button>
          <motion.img whileHover={{ scale: 1.1 }}
            className='image-update' src={updateImage} alt="Update Banner" style={{ width: '100%', height: 'auto' }} />
          <div className="update-content">
            <motion.h4
              initial={{ scale: 0 }}
              animate={{ scale: 1 }}
              transition={{ delay: 1.5, duration: 0.1 }}
            >{t.update_1}</motion.h4>
            <hr />
            <motion.h5
              initial={{ scale: 0 }}
              animate={{ scale: 1 }}
              transition={{ delay: 1.6, duration: 0.1 }}
            >{t.update_2}</motion.h5>
            <motion.p
              initial={{ scale: 0 }}
              animate={{ scale: 1 }}
              transition={{ delay: 1.7, duration: 0.1 }}
            >
              {t.update_3}
            </motion.p>
            <motion.p
              initial={{ scale: 0 }}
              animate={{ scale: 1 }}
              transition={{ delay: 1.7, duration: 0.1 }}
            >
              {t.update_10}
            </motion.p>
            <motion.h5
              initial={{ scale: 0 }}
              animate={{ scale: 1 }}
              transition={{ delay: 1.8, duration: 0.1 }}
            >{t.update_4}</motion.h5>
            <motion.ul
              initial={{ scale: 0 }}
              animate={{ scale: 1 }}
              transition={{ delay: 1.9, duration: 0.1 }}
            >
              <li>{t.update_5}</li>
              <li>{t.update_6}</li>
              <li>{t.update_7}</li>
            </motion.ul>
            <motion.h5
              initial={{ scale: 0 }}
              animate={{ scale: 1 }}
              transition={{ delay: 2.0, duration: 0.1 }}
            >{t.update_8}</motion.h5>
            <motion.p
              initial={{ scale: 0 }}
              animate={{ scale: 1 }}
              transition={{ delay: 2.1, duration: 0.1 }}
            >
              {t.update_9}
            </motion.p>
            <hr />
            <motion.h6
              initial={{ scale: 0 }}
              animate={{ scale: 1 }}
              transition={{ delay: 2.2, duration: 0.1 }}
            >v0.2</motion.h6>
          </div>
        </motion.div>
      )}
      
      {showContactForm && (
  <motion.div
    initial={{ x: '100vw' }}
    animate={{ x: 0 }}
    transition={{ type: 'spring', stiffness: 80 }}
    className={`contact-form-panel ${showContactForm ? 'active' : ''}`}
  >
    <button className="close-button" onClick={close}>CLOSE</button>
    {isWaiting ? (
      <div className="waiting-message">
        <motion.h5
          initial={{ scale: 0 }}
          animate={{ scale: 1 }}
          transition={{ delay: 2.0, duration: 0.1 }}
        >
          {t.alert_2}
        </motion.h5>
      </div>
    ) : (
      <form className="contact-form" onSubmit={handleSubmit}>
        <motion.label
          initial={{ scale: 0 }}
          animate={{ scale: 1 }}
          transition={{ delay: 1.5, duration: 0.1 }}
          htmlFor="senderName"
        >
          {t.formSendName}
        </motion.label>
        <motion.input
          initial={{ scale: 0 }}
          animate={{ scale: 1 }}
          transition={{ delay: 1.6, duration: 0.1 }}
          type="text"
          id="senderName"
          name="senderName"
          placeholder="Your name"
          value={senderName}
          onChange={(e) => setSenderName(e.target.value)}
          disabled={isSubmitting}
        />

        <motion.label
          initial={{ scale: 0 }}
          animate={{ scale: 1 }}
          transition={{ delay: 1.7, duration: 0.1 }}
          htmlFor="message"
        >
          {t.formFeedback}
        </motion.label>
        <motion.textarea
          initial={{ scale: 0 }}
          animate={{ scale: 1 }}
          transition={{ delay: 1.8, duration: 0.1 }}
          id="message"
          name="message"
          placeholder="Type your message here"
          value={message}
          onChange={(e) => setMessage(e.target.value)}
          disabled={isSubmitting}
        ></motion.textarea>

        <motion.button
          initial={{ scale: 0 }}
          animate={{ scale: 1 }}
          transition={{ delay: 1.9, duration: 0.1 }}
          type="submit"
          disabled={isSubmitting}
        >
          {isSubmitting ? "กำลังส่ง..." : "Send Message"}
        </motion.button>
      </form>
    )}

    {submissionStatus && (
      <div className="submission-status">
        {submissionStatus}
      </div>
    )}

    <hr />
    <div className="infoMessage-panel">
      <motion.h6
        initial={{ scale: 0 }}
        animate={{ scale: 1 }}
        transition={{ delay: 2.0, duration: 0.1 }}
      >
        {t.formMessage_1}
      </motion.h6>
      <motion.ul
        initial={{ scale: 0 }}
        animate={{ scale: 1 }}
        transition={{ delay: 2.1, duration: 0.1 }}
      >
        <li>{t.formMessage_2}</li>
        <li>{t.formMessage_3}</li>
        <li>{t.formMessage_4}</li>
      </motion.ul>
    </div>
    <img
      className="hero-image-bottom"
      src={bomForm}
      alt="Hero Image"
      style={{ width: '100%', height: 'auto' }}
    />
  </motion.div>
)}

{showPrivacy && (
        <motion.div
          initial={{ opacity: 0 }}
          animate={{ opacity: 1 }}
          exit={{ opacity: 0 }}
          transition={{ duration: 0.5 }}
          className="privacy-panel"
        >
          <button className="close-button" onClick={close}>CLOSE</button>
          <div className="privacy-content">
            <h4>{t.privacyTitle}</h4>
            <p>{t.effectiveDate}</p>
            <p dangerouslySetInnerHTML={{ __html: t.welcome }} />
            <h5>{t.informationWeCollectTitle}</h5>
            <p>{t.informationWeCollect}</p>
            <ul>
              <li><strong>{t.contactInfo.split(':')[0]}</strong>: {t.contactInfo.split(':')[1]}</li>
              <li><strong>{t.usageInfo.split(':')[0]}</strong>: {t.usageInfo.split(':')[1]}</li>
              <li><strong>{t.additionalInfo.split(':')[0]}</strong>: {t.additionalInfo.split(':')[1]}</li>
            </ul>
            <h5>{t.howWeUseInfoTitle}</h5>
            <p>{t.howWeUseInfo}</p>
            <ul>
              <li>{t.respondToInquiries}</li>
              <li>{t.improveExperience}</li>
              <li>{t.notifyUpdates}</li>
              <li>{t.analyzeUsage}</li>
            </ul>
            <h5>{t.shareInfoTitle}</h5>
            <p>{t.shareInfo}</p>
            <ul>
              <li>{t.legalRequirements}</li>
              <li>{t.protectRights}</li>
              <li>{t.improveService}</li>
            </ul>
            <h5>{t.retainInfoTitle}</h5>
            <p>{t.retainInfo}</p>
            <h5>{t.yourRightsTitle}</h5>
            <p>{t.yourRights}</p>
            <h5>{t.policyChangesTitle}</h5>
            <p>{t.policyChanges}</p>
            <h4>{t.cookiePolicyTitle}</h4>
            <p>{t.cookieEffectiveDate}</p>
            <p>{t.cookieUse}</p>
            <h5>{t.whatAreCookiesTitle}</h5>
            <p>{t.whatAreCookies}</p>
            <h5>{t.howWeUseCookiesTitle}</h5>
            <p>{t.howWeUseCookies}</p>
            <ul>
              <li>{t.forAnalysis}</li>
              <li>{t.improveExperienceWithCookies}</li>
            </ul>
            <h5>{t.manageCookiesTitle}</h5>
            <p>{t.manageCookies}</p>
            <p dangerouslySetInnerHTML={{ __html: t.questions }} />
          </div>
        </motion.div>
      )}
      
      <div className="logo">
        <img src={logoBrand} alt="Logo" className="logo-image" />
      </div>
      <div>
        
      <motion.button
        className="support-dev-button"
        whileHover={{ scale: 1.1 }}
        initial={{ scale: 0 }}
        animate={{ scale: 1 }}
        transition={{ duration: 0.1 }}
        onClick={handleDevButtonClick}
        disabled={isDevButtonDisabled}
      >
        <img src={isDevButtonDisabled ? icon_liked: icon_like} alt="Heart" className="heart-icon" />
        <motion.span whileHover={{ scale: 1.1 }}
        >{isDevButtonDisabled ? t.supportLike_2 : t.supportLike_1}</motion.span>
      </motion.button>

      </div>
      
      <div className="content">
      
      <div className="switch" data-language={language} onClick={toggleLanguage}>
      <motion.div className="handle" layout transition={spring}>
      <h6 className="language-label">{isEnglish ? "TH" : "EN"}</h6>
      </motion.div>
    </div>


       <div className="menu">

       <img src={logowigi} alt="Logowigi" style={{ maxWidth: '100px', height: 'auto', marginRight: 'auto' }} />

        
        <motion.button 
          onClick={toggleStory}
          whileHover={{ scale: 1.1 }}
          initial={{ scale: 0 }}
          animate={{ scale: 1 }}
          transition={{ delay: 0.1, duration: 0.1 }}
        >
          Story
          <img className='imgbt' src={btStory} alt="Story" />
        </motion.button>
        
        <motion.button 
          onClick={toggleCharacters}
          whileHover={{ scale: 1.1 }}
          initial={{ scale: 0 }}
          animate={{ scale: 1 }}
          transition={{ delay: 0.2, duration: 0.1 }}
        >
          Characters
          <img className='imgbt' src={btCha} alt="Characters" />
        </motion.button>
        
        <motion.button 
          whileHover={{ scale: 1.1 }}
          initial={{ scale: 0 }}
          animate={{ scale: 1 }}
          transition={{ delay: 0.3, duration: 0.1 }}
        >
          Products
          <img  src={btPro} alt="Products" />
        </motion.button>
        
        <motion.button 
          onClick={toggleUpdate}
          whileHover={{ scale: 1.1 }}
          initial={{ scale: 0 }}
          animate={{ scale: 1 }}
          transition={{ delay: 0.4, duration: 0.1 }}
        >
          Update
          <img  src={btUp} alt="Update" />
        </motion.button>
        
        <motion.button 
          onClick={toggleContactForm}
          whileHover={{ scale: 1.1 }}
          initial={{ scale: 0 }}
          animate={{ scale: 1 }}
          transition={{ delay: 0.5, duration: 0.1 }}
        >
          Contact
          <img  src={btCon} alt="Contact" />
        </motion.button>
        
        <motion.button 
          onClick={toggleGallery}
          whileHover={{ scale: 1.1 }}
          initial={{ scale: 0 }}
          animate={{ scale: 1 }}
          transition={{ delay: 0.6, duration: 0.1 }}
        >
          Gallery
          <img  src={btGallery} alt="Gallery" />
        </motion.button>
      </div>

        <div className="hamburger-menu" onClick={toggleMenu}>
          <div className="bar"></div>
          <div className="bar"></div>
          <div className="bar"></div>
        </div>
        {menuOpen && (
          <div className="dropdown-menu">
            <motion.button onClick={toggleStory}           whileHover={{ scale: 1.1 }}
          initial={{ scale: 0 }}
          animate={{ scale: 1 }}
          transition={{ delay: 0.5, duration: 0.1 }}>
              Story

            </motion.button>
            <motion.button onClick={toggleCharacters}           whileHover={{ scale: 1.1 }}
          initial={{ scale: 0 }}
          animate={{ scale: 1 }}
          transition={{ delay: 0.6, duration: 0.1 }}>
              Characters

            </motion.button>
            <motion.button            whileHover={{ scale: 1.1 }}
          initial={{ scale: 0 }}
          animate={{ scale: 1 }}
          transition={{ delay: 0.7, duration: 0.1 }}>
              Products

            </motion.button>
            <motion.button onClick={toggleUpdate}           whileHover={{ scale: 1.1 }}
          initial={{ scale: 0 }}
          animate={{ scale: 1 }}
          transition={{ delay: 0.8, duration: 0.1 }}>
              Update

            </motion.button>
            <motion.button onClick={toggleContactForm}           whileHover={{ scale: 1.1 }}
          initial={{ scale: 0 }}
          animate={{ scale: 1 }}
          transition={{ delay: 0.9, duration: 0.1 }}>
              Contact

            </motion.button>
            <motion.button onClick={toggleGallery}           whileHover={{ scale: 1.1 }}
          initial={{ scale: 0 }}
          animate={{ scale: 1 }}
          transition={{ delay: 1.0, duration: 0.1 }}>
              Gallery

            </motion.button>
          </div>
        )}
      </div>

      {!cookieAccepted && !cookieRejected && (
        <div className="cookie-notification">
          <p>{t.cookie}</p>
          <button onClick={handleAcceptCookies}>{t.cookie_1}</button>
          <button onClick={handleRejectCookies}>{t.cookie_2}</button>
        </div>
      )}

      
      <div className="black-bar">
        <span>Wigiway Studio | 2024 <a onClick={togglePrivacy} >| Privacy</a></span>
        <div className="social-links">

          <a href="https://youtube.com/@wigiway?si=G_jV_zXBTWYS93tg" target="_blank" rel="noopener noreferrer">
            <img src={youtubeIcon} alt="YouTube" />
          </a>
        </div>
      </div>
      <ToastContainer />
    </div>
  );
}

export default App;
